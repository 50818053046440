import * as React from "react"

const FreeTrialInHero = () => (
    <div className="landing-baner">
        <span className="landing-baner-title">
            Get your
        </span>
        <span className="landing-baner-subTitle">
            $25 CREDIT
        </span>
        <span className="landing-baner-title">
            to try our service
        </span>
        <span className="landing-baner-subTitle">
            FOR FREE
        </span>
        
        <a
            id="btnFreeTrialInHero"
            href="/free-trial"
            className="button button-secondary"
        >
            get now
        </a>
    </div>
)

export default FreeTrialInHero

