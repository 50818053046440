import React from 'react';
import Layout from "../../components/layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import FreeTrialInHero from "../../components/banners/FreeTrialInHero"

import { BaseUrlLink} from "../../helpers/baseUrl";

function Success() {
    return (
        <Layout>
            <div className='landing-intro page-intro'>
                <div className='landing-intro__wrapper wrapper'>
                    <div className="landing-intro__info">
                        <Breadcrumbs/>
                        <h1 className="page-intro__title landing-intro__title">Your message has been sent successfully!</h1>
                        <ul className="intro-list">
                            <li className="intro-description">We can write your YOUR INSTRUCTIONS per YOUR INSTRUCTIONS</li>
                            <li className="intro-description">We deliver your ON TIME ON TIME</li>
                            <li className="intro-description">We pledge 100% SATISFACTION for your</li>
                        </ul>
                        <div className="landing-intro__actions">
                            <a href={`${BaseUrlLink}/order/new`} className="button button-primary">Order now</a>
                        </div>
                    </div>
                    <div className="landing-intro__banner">
                        <FreeTrialInHero/>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Success;